<template>
<div>

	<post1 :accroche="$t('label_risk')"
				 date="12/09/2021"
				 auteur="Nicolat L."
				 :fonction="$t('property_analyst')"
				 avatar="1846"
				 comments=""
				 like=""
				 :xml="selected_xml"
				 authorinfo="" />
</div>
</template>


<script>
import post1 from "@/components/post1.vue";

export default {
	name: 'strategie',
	components: {
		post1
	},
	props: {},
	data: () => ({

		xml_fr: `

		<?xml version="1.0"?>
			<blogs>
		<titre> Les risques liés à l'investissement en berlcoin  </titre>
		<soustitre> Si l'investissement dans l'immobilier présente un risque global faible, bien connaître les risques spécifiques permet de les maitriser.</soustitre>
		<illustration>https://media.berlcoin.fr/20.jpg</illustration>


					<texte>
	 Tout investissement présente un risque de perte en capital. La gestion du risque pour le Berlcoin n'est pas forcement de prendre le moins de risque possible mais d'évaluer le meilleur ration entre le risque pris et le bénéfice et de décider en toute connaissance de causes.

Investir dans l'immobilier présentent un certains nombres de risques que limite fortement le recours au Berlcoin. Inversement l'utilisation du Berlcoin fait courir aux investisseurs d'autres risques.

Nous décrivons dans ce texte les différents types de risques et la façon dont nous les gérons ou cherchons à en limiter l'impact si un événement se produit.
					</texte>
		<category>

		<libelle>Immobilier</libelle>
		<cat_id>IM</cat_id>


			<blog>
		<reference>R1</reference>
		<titre>Risque de destruction des biens immobiliers mis en garantie</titre>
		<texte>Un événement majeur détruit les biens mis en garantie. Une guerre, un tremblement de terre, un affaissement des sols ou d'autres événements peuvent détruire partiellement ou totalement des immeubles.
		Conséquence:
		La valeur de référence des tokens sera réduite car la valeur totale des biens sera amputée de celle des biens détruits, la rentabilité sera impactée négativement.
		Gestion du risque: Les biens sont assurés et le risque est mutualisé.
		Niveau de risque: Faible</texte>
		</blog>

		<blog>
	<reference>R2</reference>
	<titre>Risque de moindre rendement</titre>
	<texte>
	l’immobilier est un actif qui génère des revenus, à condition de pouvoir louer le bien et de trouver un locataire solvable.         La rentabilité locative d’un logement (rapport entre le montant annuel des loyers et son prix d’achat net de frais d’acquisition) n’a cessé de baisser sur la dernière décennie.         L’appréciation des loyers, relativement encadrée, ne suit en effet pas le même rythme de progression que celui des logements.
			Conséquence: Perte de performance financière
			Gestion du risque: L'achat de biens immobiliers dans des zones centrales et très tendues limite considérablement le risque.
			Par ailleurs le rendement du Berlcoin est basé sur le VDP Immobilienpreisindex et non sur les loyers
			Niveau de risque: Faible
	</texte>
	</blog>

	<blog>
<reference>R3</reference>
<titre>Le risque de moins-value</titre>
<texte>
Les prix ne sont pas toujours orientés à la hausse. L’ampleur de la hausse récente des prix de la pierre est inédite sur les cinquante dernières années. Un phénomène similaire avait eu lieu entre 1985 et 1990, notamment en France, entretenu par la spéculation des marchands de biens.
				 Conséquence: Perte de performance financière
				 Gestion du risque: L'achat de biens immobiliers dans des zones centrales et très tendues limite considérablement le risque.
				 Le choix de villes comme Berlin plus encore car elle est en phase de rattrapage par rapport à d'autres capitales (y compris régionales).
				 Niveau de risque: Faible
</texte>
</blog>




		</category>
		<category>
		<libelle>Technique</libelle>
		<cat_id>TC</cat_id>

		<blog>
		<reference></reference>
		<titre>Risque de saturation du réseau Stellar</titre>
		<texte> A mesure que les smart contracts se démocratisent, la consommation en espace disque, en réseau, et en puissance de calcul augmente proportionnellement et engendre une saturation du réseau.
		Conséquence: Le coût  des transactions augmente.
		Gestion du risque: Attendre un moment plus propice pour le passage des transactions.
		Niveau de risque: Faible</texte>
		</blog>


		<blog>
		<reference></reference>
		<titre>Risque de saturation du réseau Ethereum</titre>
		<texte> A mesure que les smart contracts se démocratisent, la consommation en espace disque, en réseau, et en puissance de calcul augmente proportionnellement et engendre une saturation du réseau.
		Conséquence: Le coût  des transactions augmente.
		Gestion du risque: Ne pas utiliser de place de marché utilisant ce réseau ou attendre un moment plus propice pour le passage des transactions.
		Niveau de risque: Elévé</texte>
		</blog>



		</category>


		<category>

		<libelle>Blockchain</libelle>
		<cat_id>BC</cat_id>

		<blog>
		<reference>R2</reference>
		<titre>  Risque de perte ou de vol du support de la clé privée du souscripteur</titre>
		<texte>Toutes les transactions réalisées sur la blockchain se font par l’intermédiaire de la clé publique et privée du souscripteur.
		En cas de perte de la clé privée, les fonds et jetons détenus seront définitivement bloqués puisque personne ne peut autoriser de transfert.
		Nous ne possédons pas les clés privées et en aucun cas une clé privée ne doit être communiquée à une tierce personne.
		Nous attirons l’attention de tout utilisateur qu’il est de la responsabilité du souscripteur de conserver l’accès à son adresse Ethereum via sa clé privée et sa clé publique.
		Conséquence: Impossibilité d'accès aux tokens
		Gestion du risque: Conserver plusieurs copies des clés dans des endroits différents et sûrs.
		Niveau de risque: Non quantificable</texte>
		</blog>



					<blog>
					<reference></reference>
					<titre>Risque lié à la volatilité du Lumens</titre>
					<texte>
					Le cours du Lumens qui sert à payer les transactions peut varier à la hausse. Toutefois, Stellar est un réseau géré par un organisme à but non lucratif et n'a aucun intérêt à l'augmentation du coût des transactions.
					Conséquence: Augmentation des coûts de transactions
					Gestion du risque: Provisionner suffisant de Lumens pour financier les transactions à venir.
					Niveau de risque: Moyen</texte>
					</blog>

		<blog>
		<reference></reference>
		<titre>Risque lié à la volatilité de l'Ether</titre>
		<texte>
		Le cours de l'ether varie de façon substantielle tous les jours, avec des variations à la hausse comme à la baisse qui peuvent être de plus de 10%.
		L'achat d'ether pour acheter des tokens berlcoin peut donc présenter un risque de perte de valeur entre les deux transactions.
		Inversement, lors d'une revente de token berlcoin en Ether, la conversion des Ether en euro présente un risque de dévalorisation.
		Conséquence: Pertes financières
		Gestion du risque: Ne pas utiliser de place de marché utilisant le réseau Ethereum et utiliser une place de marché en Euro, utiliser la place de marché à cours garanti, ne pas attendre pour convertir en euro ou en berlcoin.
		Niveau de risque: Elévé</texte>
		</blog>




		</category>



		<category>


		<libelle>Monétaire</libelle>
		<cat_id>MT</cat_id>




		<blog>
		<reference></reference>
		<titre>Risque de relèvement brutal des taux d'intérêt</titre>
		<texte>
		Une augmentation brutale des taux d'intérêt peut poser des problèmes de liquidité du marché immobilier par une insolvabilité d'un certain nombre d'acheteurs qui seront de fait exclus du marché.
		Conséquence: Baisse des prix de l'immobilier
		Gestion du risque:  L'achat de biens immobiliers dans des zones centrales et très tendues limite considérablement le risque.
		Niveau de risque: Moyen</texte>
		</blog>

		<blog>
		<reference></reference>
		<titre>  Risque de disparition de l'euro</titre>
		<texte>La disparition de la zone euro et un retour à des monnaies locales.
		Conséquence: La place de marché à cours garanti utilisera une autre monnaie.
		Une baisse des prix de l'immobilier et de la rentabilité sera une conséquence majeure, ainsi que le risque de volatilité entre les monnaies nationales.
		Gestion du risque: L'investissement dans des biens immobiliers réduit le risque des conséquences d'une disparition de la zone euro puisqu'il restera toujours la propriété des immeubles.
		Niveau de risque: Faible</texte>
		</blog>


		<blog>
		<reference></reference>
		<titre>Risque de resserrement d'accès à l'emprunt</titre>
		<texte>Les banques peuvent réduire l'accés à l'emprunt, à la fois pour les sociétés et les particuliers.
		Conséquence:  Une exclusion d'une partie des acheteurs d'un marché entraine une baisse des prix, une réduction de la liquidité et une augmentation des stocks.
		Gestion du risque: L'achat de biens immobiliers dans les zones centrales et très tendues limite le risque de baisse de prix, la qualité des investissements précédents limite le risque de refus de prêt par nos partenaires bancaires si nous avons recours au banque.
		Niveau de risque: Moyen</texte>
		</blog>



		</category>



		<category>
		<libelle>Economique</libelle>
		<cat_id>EC</cat_id>




		<blog>
		<reference>R5</reference>
		<titre>Risque de disparition de la société Fraction.re SAS </titre>
		<texte>La société Fraction.re SAS peut arrêter ses activités ou être mise en liquidation judiciaire.
		Conséquence: La place de marché à cours garanti peut ne plus être active. A noter: vos tokens sont en sécurité dans la blockchain et il n'y a donc pas de risque de perte de vos tokens berlcoin. Par ailleurs les pools de liquidités sont décentralisées et ne sont pas gérés par une société en particulier.
					 Gestion du risque: Vendre ses tokens sur une place de marché secondaire ou saisir des biens en garantie.
		Niveau de risque: Faible</texte>
		</blog>


		<blog>
		<reference>R6</reference>
		<titre>Risque de disparition de la société de Gestion</titre>
		<texte>La société de gestion peut arrêter ses activités ou être mise en liquidation judiciaire.
		Conséquence: Nous transférerons la gestion à une autre société. Cela peut engendrer des frais importants liés à l'inscription cadastre qui peuvent réduire provisoirement la valeur de référence des tokens.
		Gestion du risque: Nous effectuons un contrôle annuel sur la société de gestion et un representant de  est membre de droit du conseil d'administration.
		Niveau de risque: Faible</texte>
		</blog>



		<blog>
		<reference>R1</reference>
		<titre>Risque de liquidité sur le marché primaire</titre>
		<texte>
		En cas de crise, il peut arriver que tous les détenteurs de tokens désirent vendre simultanément et ne trouvent pas suffisamment d'acheteurs et que la société  n'ait pas les liquidités pour racheter les tokens à leur valeur de référence.
		Conséquence:
		La place de marché suspend ses activités provisoirement. Les détenteurs de tokens peuvent attendre la fin de la crise ou exercer une prise de garantie.
		Gestion du risque: Conservation d'un volet de liquidité suffisant et encours bancaire.
		Niveau de risque: Moyen</texte>
		</blog>

		<blog>
		<reference>R2</reference>
		<titre>Risque de change sur les marchés secondaires en Ether ou en dollars</titre>
		<texte>
		Sur les places de marchés secondaires, les transactions se font principalement en Ether ou en dollars américains.
		Le cours du dollar peut varier et le cours de l'ether est très volatile avec des variations qui peuvent atteindre 50% en une semaine.
		Conséquence: Pertes financières
		Gestion du risque: Vérifier les cours au moment de la revente et convertir immédiatement en euros.
		Niveau de risque: Elévé</texte>
		</blog>

		<blog>
		<reference>R3</reference>
		<titre>Risque lié à la valorisation des jetons sur les places de marchés secondaires</titre>
		<texte>
		Le cours d’échange du token contre des monnaies ayant cours légal ou d’autres cryptomonnaies dépend de l’offre et de la demande sur les places de marchés secondaires.      Les achats et ventes de tokens réalisés par  sur les marchés secondaires sont susceptibles d’influer le cours d’échange du token.      La société  décline toute  responsabilité sur les valeurs d’achat ou de revente des jetons sur les marchés secondaires.
		Conséquence: Pertes financières
		Gestion du risque: Utiliser la place de marché à cours garanti
		Niveau de risque: Elévé</texte>
		</blog>


		</category>

		<category>
		<libelle>Juridique</libelle>
		<cat_id>IM</cat_id>



		<blog>
		<reference></reference>
		<titre>Risque fiscal</titre>
		<texte>Une augmentation des impôts sur les mutations immobilières ou des taxes foncières.
		Conséquence: Une baisse de la rentabilité
		Gestion du risque:  Nous investissons en Allemagne où la fiscalité liée à l'immobilier est très faible comparée à la France. Si la baisse de rentabilité est trop importante, nous investirons dans un autre pays. Toutefois, en Allemagne les modifications fiscales ne sont généralement pas aussi brutales et imprévisibles qu'en France.
		Niveau de risque: Moyen</texte>
		</blog>


		<blog>
		<reference></reference>
		<titre>Risque législatif local</titre>
		<texte>Lois locales impactant les possibilités de revente ou limitant les loyers. Par exemple la loi sur la limitation des loyers a été déclarée illégale par la cour suprême allemande et a interdit à un land de créer une telle loi. La loi sur le miroir des loyers est en cours d'examen.
		Conséquence:  Ces lois peuvent limiter la rentabilité des investissements.
		Gestion du risque:  La stratégie de gestion n'est pas basée sur des loyers excessifs. Nous restons donc dans la fourchette de prix du miroir des loyers. Par ailleurs, la valeur de référence du Berlcoin est basée sur le VDP Immobilienpreisindex et non sur le rendement locatif.
		Niveau de risque: Moyen
		</texte>
		</blog>


		<blog>
		<reference></reference>
		<titre>  Risque législatif fédéral ou européen sur l'interdiction des tokens</titre>
		<texte>L'utilisation et la détention de tokens deviennent illégaux.
		Conséquence: Arret de l'activité en Europe ou dans les pays où les tokens sont devenus illégaux.
		Gestion du risque: Transfert de la société aux USA.
		Niveau de risque: Faible</texte>
		</blog>


		</category>

		</blogs>

        `,

		xml_en: `

				<?xml version="1.0"?>
					<blogs>
				<titre>The risks of investing in berlcoin  </titre>
				<soustitre> While investing in real estate has a low overall risk, knowing the specific risks well helps to control them.</soustitre>
				<illustration>https://media.berlcoin.fr/20.jpg</illustration>


							<texte>
							Any investment carries a risk of capital loss. Risk management for Berlcoin is not necessarily about taking the least amount of risk possible but about evaluating the best ration between the risk taken and the benefit and making an informed decision.

							Investing in real estate presents a number of risks that are greatly limited by the use of Berlcoin. Conversely, the use of Berlcoin exposes investors to other risks.

							In this text we describe the different types of risks and how we manage them or seek to limit their impact if an event occurs.
							</texte>
				<category>

				<libelle>Real estate</libelle>
				<cat_id>IM</cat_id>


					<blog>
				<reference>R1</reference>
				<titre>Risk of destruction of collateralized real estate</titre>
				<texte>A major event destroys the collateral. War, earthquake, subsidence, or other events may partially or totally destroy real property.
Consequence:
The reference value of the tokens will be reduced because the total value of the assets will be reduced by the value of the destroyed assets, the profitability will be negatively impacted.
Risk management: The assets are insured and the risk is mutualized.
Risk level: Low</texte>
				</blog>


		<blog>

		<reference>R2</reference>
		<titre>Risk of lower return</titre>
		<texte>
		real estate is an asset that generates income, provided that the property can be rented and a solvent tenant can be found.         The rental profitability of a property (the ratio between the annual amount of rents and its purchase price net of acquisition costs) has been steadily declining over the past decade.         The appreciation of rents, relatively framed, does not follow the same rhythm of progression as that of housing.
		Consequence: Loss of financial performance
		Risk management: The purchase of real estate in central and very tense areas considerably limits the risk.
		In addition, the Berlcoin yield is based on the VDP Immobilienpreisindex and not on rents
		Risk level: Low
		</texte>
		</blog>


<blog>
<reference>R3</reference>
<titre>The risk of loss of value</titre>
<texte>
Prices are not always trending upward. The magnitude of the recent rise in stone prices is unprecedented over the past fifty years. A similar phenomenon had taken place between 1985 and 1990, particularly in France, sustained by speculation by property dealers.
 Consequence: Loss of financial performance
 Risk management: Buying real estate in central and very tense areas limits the risk considerably.
 The choice of cities such as Berlin is even more important as it is in a catching-up phase compared to other (also regional) capitals.
 Risk level: Low
</texte>
</blog>
</category>


<category>
<libelle>Technique</libelle>
<cat_id>TC</cat_id>

<blog>
<reference></reference>
<titre>Stellar network saturation risk</titre>
<texte> As smart contracts become more democratic, the consumption of disk space, network, and computing power increases proportionally and causes network saturation.
Consequence: The cost of transactions increases.
Risk management: Wait for a better time for the transactions to pass.
Risk level: Low</texte>
</blog>


<blog>
<reference></reference>
<titre>Risk of Ethereum network saturation</titre>
<texte> As smart contracts become more democratic, the consumption of disk space, network, and computational power increases proportionally and causes network saturation.
Consequence: The cost of transactions increases.
Risk management: Do not use a market place using this network or wait for a more convenient time for the transactions.
Risk level: High</texte>
</blog>

</category>



<category>

<libelle>Blockchain</libelle>
<cat_id>BC</cat_id>

<blog>
<reference>R2</reference>
<titre> Risk of loss or theft of subscriber's private key media</titre>
<texte>All transactions performed on the blockchain are done through the subscriber's public and private key.
If the private key is lost, the funds and tokens held will be permanently blocked since no one can authorize a transfer.
We do not own the private keys and under no circumstances should a private key be given to a third party.
We draw the attention of every user that it is the responsibility of the subscriber to keep the access to his Ethereum address via his private key and his public key.
Consequence: Inability to access tokens
Risk management: Keep several copies of the keys in different and safe places.
Risk level: Not quantifiable</texte>
</blog>



<blog>
<reference></reference>
<titre>Lumen volatility risk</titre>
<texte>
The price of Lumens, which is used to pay for transactions, can fluctuate upward. However, Stellar is a non-profit run network and has no interest in increasing the cost of transactions.
Consequence: Increased transaction costs
Risk management: Provision sufficient Lumens to finance future transactions.
Risk level: Medium</texte>
</blog>

<blog>
<reference></reference>
<titre>Ether volatility risk</titre>
<texte>
The price of ether fluctuates substantially every day, with both up and down swings that can be more than 10%.
Buying ether to buy berlcoin tokens can therefore present a risk of loss in value between the two transactions.
Conversely, when reselling berlcoin tokens in Ether, the conversion of Ether to Euro presents a risk of devaluation.
Consequence: Financial losses
Risk management: Do not use a marketplace using the Ethereum network and use a Euro marketplace, use the guaranteed price marketplace, do not wait to convert to Euro or Berlcoin.
Risk level: High</texte>
</blog>




</category>




<category>


<libelle>Monetary</libelle>
<cat_id>MT</cat_id>




<blog>
<reference></reference>
<titre>Risk of abrupt interest rate hike</titre>
<texte>
A sudden increase in interest rates can cause liquidity problems in the real estate market through insolvency of a number of buyers who will be effectively shut out of the market.
Consequence: Decline in real estate prices
Risk management:  Buying real estate in central, high-tension areas limits the risk considerably.
Risk level: Medium</texte>
</blog>

<blog>
<reference></reference>
<titre>Risk of disappearance of the euro</titre>
<texte>The demise of the eurozone and a return to local currencies.
Consequence: the guaranteed price marketplace will use another currency.
Lower real estate prices and profitability will be a major consequence, as well as the risk of volatility between national currencies.
Risk management: Investing in real estate reduces the risk of the consequences of a disappearance of the Eurozone since ownership of the real estate will still remain.
Risk level: Low</texte>
</blog>


<blog>
<reference></reference>
<titre>Risk of tightening access to borrowing</titre>
<texte>Banks may reduce access to borrowing, both for corporations and individuals.
Consequence: Exclusion of a portion of buyers from a market leads to lower prices, reduced liquidity and increased inventories.
Risk management: Buying real estate in central and very tense areas limits the risk of price decline, the quality of previous investments limits the risk of loan refusal by our banking partners if we use the bank.
Risk level: Medium</texte>
</blog>



</category>



<category>
<libelle>Economic</libelle>
<cat_id>EC</cat_id>




<blog>
<reference>R5</reference>
<titre>Risk of disappearance of the company Fraction.re SAS </titre>
<texte>The company Fraction.re SAS may stop its activities or be put into judicial liquidation.
Consequence: the guaranteed price marketplace may no longer be active. Please note: Your tokens are safe in the blockchain and therefore there is no risk of losing your berlcoin tokens. In addition, the liquidity pools are decentralized and are not managed by any one company.
 Risk management: Sell your tokens on a secondary market place or seize collateral.
Risk level: Low</texte>
</blog>


<blog>
<reference>R6</reference>
<titre>Risk of disappearance of the management company</titre>
<texte>The management company may cease operations or go into receivership.
Consequence: We will transfer the management to another company. This may result in significant costs related to the cadastral registration which may temporarily reduce the reference value of the tokens.
Risk management: We carry out an annual audit of the management company and a representative of is an ex-officio member of the board of directors.
Risk level: Low</texte>
</blog>



<blog>
<reference>R1</reference>
<titre>Liquidity risk in the primary market</titre>
<texte>
In the event of a crisis, it may happen that all token holders want to sell simultaneously and cannot find enough buyers and the company does not have the liquidity to buy back the tokens at their reference value.
Consequence:
The marketplace temporarily suspends operations. Token holders can wait until the crisis is over or exercise a warranty.
Risk management: Maintain sufficient liquidity and bank outstandings.
Risk level: Medium</texte>
</blog>

<blog>
<reference>R2</reference>
<titre>Foreign exchange risk in secondary markets in Ether or dollars</titre>
<texte>
On secondary marketplaces, trades are primarily in Ether or US dollars.
The dollar price can vary and the ether price is very volatile with variations that can reach 50% in a week.
Consequence: Financial losses
Risk management: Check prices at the time of resale and convert immediately to euros.
Risk level: High</texte>
</blog>

<blog>
<reference>R3</reference>
<titre>Risk related to the valuation of tokens on secondary marketplaces</titre>
<texte>
The exchange price of the token against legal tender or other cryptocurrencies depends on supply and demand on secondary marketplaces.      Purchases and sales of tokens on secondary markets may influence the exchange price of the token.      The company declines any responsibility on the values of purchase or resale of the tokens on the secondary markets.
Consequence: Financial losses
Risk management: Use the guaranteed price market place
Risk level: High</texte>
</blog>


</category>

<category>
<libelle>Legal</libelle>
<cat_id>IM</cat_id>



<blog>
<reference></reference>
<titre>Tax risk</titre>
<texte>An increase in real estate transfer taxes or property taxes.
Consequence: A decrease in profitability.
Risk management: We invest in Germany where the tax burden related to real estate is very low compared to France. If the decrease in profitability is too great, we will invest in another country. However, tax changes in Germany are generally not as brutal and unpredictable as in France.
Risk level: Medium</texte>
</blog>


<blog>
<reference></reference>
<titre>Local legislative risk</titre>
<texte>Local laws impacting resale opportunities or limiting rents. For example the rent limitation law was declared illegal by the German Supreme Court and prohibited a state from creating such a law. The rent mirror law is currently under review.
Consequence: These laws can limit the profitability of investments.
Risk management:  The management strategy is not based on excessive rents. We therefore remain within the price range of the rent mirror. In addition, the benchmark value of the Berlcoin is based on the VDP Immobilienpreisindex and not on the rental yield.
Risk level: Medium
</texte>
</blog>


<blog>
<reference></reference>
<titre> Federal or European legislative risk on token ban</titre>
<texte>The use and holding of tokens becomes illegal.
Consequence: Stopping the activity in Europe or in countries where tokens became illegal.
Risk management: Transfer of the company to the USA.
Risk level: Low</texte>
</blog>


</category>

				</blogs>`,


						xml_de: `

				        <?xml version="1.0"?>
								<blogs>
								<titre> Risiken bei der Investition in Berlcoin </titre>
								<soustitre> Auch wenn die Investition in Immobilien ein geringes Gesamtrisiko darstellt, hilft es, die spezifischen Risiken gut zu kennen, um sie zu beherrschen.</soustitre>
								<illustration>https://media.berlcoin.fr/20.jpg</illustration>
								<texte>
								 Jede Investition birgt das Risiko eines Kapitalverlusts. Das Risikomanagement bei Berlcoin besteht nicht unbedingt darin, das geringstmögliche Risiko einzugehen, sondern das beste Verhältnis zwischen dem eingegangenen Risiko und dem Gewinn zu bewerten und eine fundierte Entscheidung zu treffen.

								Die Investition in Immobilien birgt eine Reihe von Risiken, die durch die Verwendung des Berlcoin stark eingeschränkt werden. Umgekehrt birgt die Verwendung des Berlcoin weitere Risiken für die Anleger.

								In diesem Text beschreiben wir die verschiedenen Arten von Risiken und wie wir mit ihnen umgehen oder versuchen, ihre Auswirkungen zu begrenzen, falls ein Ereignis eintritt.
								</texte>

								<category>
								<libelle>Immobilien</libelle>
								<cat_id>IM</cat_id>
								<blog>
								<reference>R1</reference>
								<titre>Risiko der Zerstörung von verpfändeten Immobilien</titre>
								<texte>Ein großes Ereignis zerstört die als Sicherheit verpfändeten Immobilien. Krieg, Erdbeben, Bodensenkungen oder andere Ereignisse können Immobilien teilweise oder vollständig zerstören.
								Folge:
								Der Referenzwert der Token wird reduziert, da der Gesamtwert der Vermögenswerte um den Wert der zerstörten Vermögenswerte verringert wird, und die Rentabilität wird negativ beeinflusst.
								Management des Risikos: Die Güter sind versichert und das Risiko wird gepoolt.
								Risikoniveau: Niedrig</texte>
								</blog>
								<blog>
								<reference>R2</reference>
								<titre>Risiko einer geringeren Rendite</titre>
								<texte>
								Immobilien sind ein Vermögenswert, der Einkommen generiert, vorausgesetzt, man kann die Immobilie vermieten und findet einen solventen Mieter.         Die Mietrendite einer Immobilie (das Verhältnis zwischen den jährlichen Mieteinnahmen und dem Kaufpreis nach Abzug der Anschaffungskosten) ist im letzten Jahrzehnt stetig gesunken.         Die Mietpreise, die relativ stark reguliert sind, steigen nicht im gleichen Maße wie die Preise für Wohnimmobilien.
								Folge: Verlust der finanziellen Leistungsfähigkeit
								Management des Risikos: Der Kauf von Immobilien in zentralen, stark angespannten Gebieten begrenzt das Risiko erheblich.
								Außerdem basiert die Rendite des Berlcoin auf dem VDP Immobilienpreisindex und nicht auf den Mieten.
								Risikoniveau: Niedrig
								</texte>
								</blog>
								<blog>
								<reference>R3</reference>
								<titre>Das Risiko eines Wertverlusts</titre>
								<texte>
								Die Preise sind nicht immer aufwärts gerichtet. Das Ausmaß des jüngsten Anstiegs der Steinpreise ist in den letzten fünfzig Jahren beispiellos. Ein ähnliches Phänomen hatte es zwischen 1985 und 1990 insbesondere in Frankreich gegeben, das durch die Spekulation von Immobilienhändlern angeheizt wurde.
								 Folge: Verlust der finanziellen Leistungsfähigkeit
								 Management des Risikos: Der Kauf von Immobilien in zentralen, stark angespannten Gebieten verringert das Risiko erheblich.
								 Die Wahl von Städten wie Berlin, die sich im Vergleich zu anderen (auch regionalen) Hauptstädten in einer Aufholphase befindet, ist sogar noch wichtiger.
								 Risikoniveau: Niedrig
								</texte>
								</blog>
								</category>

								<category>>
								<libelle>Technik</libelle>
								<cat_id>TC</cat_id>
								<blog>
								<reference></reference>
								<titre>Risiko einer Überlastung des Stellar-Netzwerks</titre>
								<texte> Mit der zunehmenden Verbreitung von Smart Contracts steigt der Verbrauch an Speicherplatz, Netzwerk und Rechenleistung proportional an und führt zu einer Überlastung des Netzwerks.
								Folge: Die Kosten für Transaktionen steigen.
								Risikomanagement: Warten Sie auf einen günstigeren Zeitpunkt für die Durchführung von Transaktionen.
								Risikoniveau: Niedrig</texte>
								</blog>
								<blog>
								<reference></reference>
								<titre>Risiko einer Überlastung des Ethereum-Netzwerks</titre>
								<texte> Mit zunehmender Verbreitung von Smart Contracts steigt der Verbrauch an Speicherplatz, Netzwerk und Rechenleistung proportional an und führt zu einer Überlastung des Netzwerks.
								Folge: Die Kosten für Transaktionen steigen.
								Risikomanagement: Verwenden Sie keinen Marktplatz, der dieses Netzwerk nutzt, oder warten Sie auf einen günstigeren Zeitpunkt für die Durchführung von Transaktionen.
								Risikoniveau: Hoch</texte>
								</blog>
								</category>

								<category>
								<libelle>Blockchain</libelle>
								<cat_id>BC</cat_id>
								<blog>
								<reference>R2</reference>
								<titre> Risiko des Verlusts oder Diebstahls des Trägers des privaten Schlüssels des Abonnenten</titre>
								<texte>Alle Transaktionen, die in der Blockchain durchgeführt werden, erfolgen über den öffentlichen und privaten Schlüssel des Zeichners.
								Bei Verlust des privaten Schlüssels werden die gehaltenen Gelder und Token dauerhaft gesperrt, da niemand eine Übertragung autorisieren kann.
								Wir besitzen die privaten Schlüssel nicht und unter keinen Umständen darf ein privater Schlüssel an eine dritte Person weitergegeben werden.
								Wir weisen jeden Nutzer darauf hin, dass es in der Verantwortung des Abonnenten liegt, den Zugang zu seiner Ethereum-Adresse über seinen privaten und öffentlichen Schlüssel aufrechtzuerhalten.
								Konsequenz: Kein Zugriff auf die Token.
								Risikomanagement: Mehrere Kopien der Schlüssel an verschiedenen, sicheren Orten aufbewahren.
								Risikoniveau: Nicht quantifizierbar</texte>
								</blog>
								<blog>
								<reference></reference>
								<titre>Risiko durch die Volatilität von Lumens</titre>
								<texte>
								Der Kurs des Lumens, mit dem die Transaktionen bezahlt werden, kann nach oben schwanken. Stellar ist jedoch ein Netzwerk, das von einer gemeinnützigen Organisation betrieben wird, und hat kein Interesse daran, dass die Transaktionskosten steigen.
								Konsequenz: Höhere Transaktionskosten.
								Risikomanagement: Stellen Sie genügend Lumen zur Verfügung, um zukünftige Transaktionen zu finanzieren.
								Risikoniveau: Mittel</texte>
								</blog>
								<blog>
								<reference></reference>
								<titre>Risiko durch die Volatilität von Ether</titre>
								<texte>
								Der Ether-Kurs schwankt jeden Tag erheblich, wobei die Schwankungen nach oben und unten mehr als 10% betragen können.
								Der Kauf von Ether zum Kauf von Berlcoin-Tokens kann daher das Risiko eines Wertverlusts zwischen den beiden Transaktionen bergen.
								Umgekehrt besteht beim Weiterverkauf von Berlcoin-Token in Ether ein Abwertungsrisiko, wenn die Ether in Euro umgerechnet werden.
								Folge: Finanzielle Verluste
								Risikomanagement: Verwenden Sie keinen Marktplatz, der das Ethereum-Netzwerk nutzt, und verwenden Sie einen Euro-Marktplatz, verwenden Sie den Marktplatz mit garantiertem Kurs, warten Sie nicht mit dem Umtausch in Euro oder Berlcoin.
								Risikoniveau: Hoch</texte>
								</blog>
								</category>

								<category>
								<libelle>Geldmarkt</libelle>
								<cat_id>MT</cat_id>

								<blog>
								<reference></reference>
								<titre>Risiko einer abrupten Zinserhöhung</titre>
								<texte>
								Ein plötzlicher Anstieg der Zinssätze kann zu Liquiditätsproblemen auf dem Immobilienmarkt führen, da eine Reihe von Käufern zahlungsunfähig wird und de facto vom Markt ausgeschlossen wird.
								Folge: Rückgang der Immobilienpreise.
								Management des Risikos:  Der Kauf von Immobilien in zentralen und sehr angespannten Gebieten begrenzt das Risiko erheblich.
								Risikoniveau: Mittel</texte>
								</blog>

								<blog>
								<reference></reference>
								<titre> Risiko des Verschwindens des Euro</titre>
								<texte>
								Das Verschwinden der Eurozone und eine Rückkehr zu lokalen Währungen.
								Folge: Der Marktplatz mit garantiertem Kurs wird eine andere Währung verwenden.
								Ein Rückgang der Immobilienpreise und der Rentabilität wird eine wichtige Folge sein, ebenso wie das Risiko der Volatilität zwischen den nationalen Währungen.
								Management des Risikos: Die Investition in Immobilien verringert das Risiko der Folgen eines Zusammenbruchs der Eurozone, da die Immobilien immer im Besitz des Landes bleiben werden.
								Risikoniveau: Niedrig
								</texte>
								</blog>

								<blog>
								<reference></reference>
								<titre>Risiko einer restriktiveren Kreditvergabe</titre>
								<texte> Banken können den Zugang zu Krediten sowohl für Unternehmen als auch für Einzelpersonen einschränken.
								Folgen: Der Ausschluss eines Teils der Käufer von einem Markt führt zu niedrigeren Preisen, geringerer Liquidität und höheren Lagerbeständen.
								Management des Risikos: Der Kauf von Immobilien in zentralen und sehr angespannten Gebieten verringert das Risiko von Preisrückgängen, und die Qualität der bisherigen Investitionen verringert das Risiko, dass unsere Bankpartner Kredite ablehnen, wenn wir die Bank in Anspruch nehmen.
								Risikoniveau: Mittel</texte>
								</blog>

								</category>

								<category>
								<libelle>Wirtschaft</libelle>
								<cat_id>EC</cat_id>
								<blog>
								<reference>R5</reference>
								<titre>Risiko des Untergangs der Firma Fraction.re SAS </titre>
								<texte>Das Unternehmen Fraction.re SAS kann seine Geschäftstätigkeit einstellen oder in die Insolvenz gehen.
								Dies kann dazu führen, dass der Marktplatz mit garantiertem Kurs nicht mehr aktiv ist. Bitte beachten Sie: Ihre Token sind in der Blockchain sicher und es besteht daher kein Risiko, dass Sie Ihre Berlcoin-Token verlieren. Außerdem sind die Liquiditätspools dezentralisiert und werden nicht von einem bestimmten Unternehmen verwaltet.
								 Risikomanagement: Verkaufen Sie Ihre Token auf einem sekundären Marktplatz oder pfänden Sie Vermögenswerte als Sicherheit.
								Risikoniveau: Niedrig</texte>
								</blog>
								<blog>
								<reference>R6</reference>
								<titre>Risiko des Verschwindens der Verwaltungsgesellschaft</titre>
								<texte>Die Verwaltungsgesellschaft kann ihre Tätigkeit einstellen oder in Konkurs gehen.
								Die Folge: Wir werden die Verwaltung an eine andere Gesellschaft übertragen. Dies kann zu erheblichen Kosten für die Eintragung in das Grundbuch führen, die den Referenzwert der Token vorübergehend verringern können.
								Risikomanagement: Wir führen eine jährliche Überprüfung der Verwaltungsgesellschaft durch und ein Vertreter von ist von Amts wegen Mitglied des Verwaltungsrats.
								Risikoniveau: Niedrig</texte>
								</blog>
								<blog>
								<reference>R1</reference>
								<titre>Liquiditätsrisiko auf dem Primärmarkt</titre>
								<texte>
								In einer Krise kann es vorkommen, dass alle Token-Inhaber gleichzeitig verkaufen wollen und nicht genügend Käufer finden, sodass das Unternehmen nicht über die Liquidität verfügt, um die Token zum Referenzwert zurückzukaufen.
								Konsequenz:
								Der Marktplatz stellt seinen Betrieb vorübergehend ein. Die Inhaber der Token können das Ende der Krise abwarten oder Sicherheiten stellen.
								Risikomanagement: Aufrechterhaltung einer ausreichenden Liquidität und ausstehender Bankkredite.
								Risikoniveau: Mittel</texte>
								</blog>
								<blog>
								<reference>R2</reference>
								<titre>Währungsrisiko auf sekundären Märkten in Ether oder Dollar</titre>
								<texte>
								Auf sekundären Marktplätzen wird hauptsächlich in Ether oder US-Dollar gehandelt.
								Der Dollarkurs kann schwanken und der Etherkurs ist sehr volatil mit Schwankungen, die innerhalb einer Woche bis zu 50 % betragen können.
								Folge: Finanzielle Verluste
								Risikomanagement: Überprüfen Sie die Kurse beim Wiederverkauf und rechnen Sie sofort in Euro um.
								Risikoniveau: Hoch</texte>
								</blog>
								<blog>
								<reference>R3</reference>
								<titre>Risiko aus der Bewertung von Token auf sekundären Marktplätzen</titre>
								<texte>
								Der Kurs, zu dem ein Token gegen gesetzliche Zahlungsmittel oder andere Kryptowährungen getauscht wird, hängt von Angebot und Nachfrage auf den sekundären Marktplätzen ab.      Die Käufe und Verkäufe von Token, die von auf sekundären Marktplätzen getätigt werden, können den Tauschkurs des Tokens beeinflussen.      Das Unternehmen übernimmt keine Verantwortung für den Wert des Kaufs oder Weiterverkaufs von Token auf sekundären Märkten.
								Folge: Finanzielle Verluste
								Risikomanagement: Nutzung des Marktplatzes mit garantiertem Kurs.
								Risikoniveau: Hoch</texte>
								</blog>
								</category>

								<category>
								<libelle>Rechtliches</libelle>
								<cat_id>IM</cat_id>
								<blog>
								<reference></reference>
								<titre>Steuerrisiko</titre>
								<texte>Eine Erhöhung der Grunderwerbssteuer oder der Grundsteuer.
								Folge: Ein Rückgang der Rentabilität.
								Risikomanagement: Wir investieren in Deutschland, wo die Besteuerung von Immobilien im Vergleich zu Frankreich sehr niedrig ist. Wenn die Rentabilität zu stark sinkt, werden wir in einem anderen Land investieren. Allerdings sind Steueränderungen in Deutschland in der Regel nicht so abrupt und unvorhersehbar wie in Frankreich.
								Risikoniveau: Mittel</texte>
								</blog>
								<blog>
								<reference></reference>
								<titre>Lokales gesetzgeberisches Risiko</titre>
								<texte>Lokale Gesetze, die sich auf die Möglichkeiten des Wiederverkaufs auswirken oder die Mieten beschränken. Beispielsweise wurde das Gesetz zur Begrenzung der Mieten vom obersten deutschen Gericht für rechtswidrig erklärt und einem Bundesland verboten, ein solches Gesetz zu schaffen. Das Gesetz über den Mietspiegel wird derzeit geprüft.
								Konsequenz: Diese Gesetze können die Rentabilität von Investitionen einschränken.
								Management des Risikos:  Die Verwaltungsstrategie basiert nicht auf überhöhten Mieten. Wir bleiben daher in der Preisspanne des Mietspiegels. Darüber hinaus basiert der Referenzwert des Berlcoin auf dem VDP Immobilienpreisindex und nicht auf der Mietrendite.
								Risikoniveau: Mittel
								</texte>
								</blog>
								<blog>
								<reference></reference> 
								<titre> Gesetzgebungsrisiko auf Bundes- oder EU-Ebene zum Verbot von Token</titre>
								<texte>Die Verwendung und der Besitz von Token werden illegal.
								Folge: Einstellung der Geschäftstätigkeit in Europa oder in den Ländern, in denen Token illegal geworden sind.
								Risikomanagement: Verlegung des Unternehmens in die USA.
								Risikoniveau: Niedrig</texte>
								</blog>
								</category>
</blogs>
				        `,

				selected_xml: "",

			}),
			beforeCreate: function () {},
			created: function () {},
			beforeMount: function () {},
			mounted: function () {
				this.$i18n.locale = this.$route.params.lang
				this.langxml();
			},
			beforeUpdate: function () {},
			updated: function () {

			},
			beforeDestroy: function () {},
			destroyed: function () {},
			computed: {
				locale() {
					return this.$i18n.locale;
				},
			},
			methods: {
				langxml() {
					if (this.$i18n.locale == "fr") this.selected_xml = this.xml_fr;
					if (this.$i18n.locale == "en") this.selected_xml = this.xml_en;
					if (this.$i18n.locale == "de") this.selected_xml = this.xml_de;

				}
			},
			watch: {
				locale() {
					this.langxml();
				},
			}
			}
			</script>

			<style scoped>
			</style>
